<template>
  <b-modal
    id="modal-sal"
    cancel-variant="outline-secondary"
    v-model="modalShow"
    cancel-title="Close"
    centered
    size="lg"
    :hide-footer="true"
    :title="`${title}`"
  >
    <div class="divider my-2">
      <div class="divider-text">

        <div>
          <strong>
            [ B ] Identitas / Karyawan / Rutinitas
          </strong>
          <!-- {{ item?.total_items }}x
          <br />
          <strong>Nama Kapal: {{ item?.label }}</strong> -->
        </div>
      </div>
    </div>
    <vue-good-table
      class="yo-good-table"
      :columns="columns"
      :rows="getRows"
      :search-options="{
        enabled: true}"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'count'"
          class="text-nowrap"
        >
          <strong>{{ props.row.count }} Orang</strong>
        </div>
        <div
          v-else-if="props.column.field === 'category'"
          class="text-nowrap"
        >
          <strong>{{ props.row.category }}</strong>
        </div>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-modal>
</template>

<script>
import moment from 'moment'
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isModalActive: {
      type: Boolean,
      required: true
    },
    item: {
      type: [Object, Array, null],
      required: true
    },
  },
  directives: {
    'b-modal': BModal,
    Ripple
  },
  // mounted() {
  //   this.init()
  // },
  computed: {
    modalShow: {
      get() {
        return this.isModalActive
      },
      set(val) {
        if (!val) {
          console.log('close modal');
          this.$emit('closeModal', false, null)
        }
      }
    },
    getRows: {
      get() {
        // this.item.children.forEach(item => {
        //   const { sandar_start_time } = item
        //   item.sandar_start_time = this.dateHumanReadAble(sandar_start_time)
        // });
        return this.item
      }
    }
  },
  watch: {
    isModalActive: function () {
      if (this.isModalActive) {
        console.log('isModalActive', this.isModalActive);
        // this.fetchDetail()
      }
    }
  },
  data() {
    return {
      loadingFetch: false,
      detailData: null,
      columns: [
        // {
        //   label: 'Pemilik',
        //   field: 'pemilik',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search Pemilik',
        //   },
        // },
        // {
        //   label: 'Boat Name',
        //   field: 'boat_name',
        //   filterOptions: {
        //     enabled: false,
        //     placeholder: 'Search Boat',
        //   },
        // },
        {
          label: 'Kunjungan Ke',
          field: 'category',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Kunjungan Ke',
          },
        },
        {
          label: 'Jumlah-nya',
          field: 'count',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Jumlah-nya',
          },
        },
        // {
        //   label: 'Whatsapp',
        //   field: 'phone',
        //   // type: 'date',
        //   // formatFn: this.dateHumanReadAble,
        //   filterOptions: {
        //     enabled: true,
        //     // 2024-03-12T05:15:00.000Z
        //     placeholder: 'Search Nomor Whatsapp',
        //   },
        // },
        // {
        //   label: 'Jam Masuk',
        //   field: 'clock_in',
        //   // type: 'date',
        //   // formatFn: this.dateHumanReadAble,
        //   filterOptions: {
        //     enabled: true,
        //     // 2024-03-12T05:15:00.000Z
        //     placeholder: 'Search Jam Masuk',
        //   },
        // },
        // {
        //   label: 'Kunjungan Ke',
        //   field: 'category',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search ke',
        //   },
        // },
      ],
    }
  },
  // created() {
  //   this.fetchHeadline()
  // },
  methods: {
    moment,
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - hh:mm A')
      if (format === 'Invalid date') {
        return 'Belum Diisi ❗️'
      }
      return format
    },
    dateHumanReadAbleSingle(value) {
      const format = moment(value).format('MMMM YYYY')
      if (format === 'Invalid date') {
        return 'Belum Diisi ❗️'
      }
      return format
    },
    // fetchDetail() {
    //   console.log('this.uri', this.uri);
    //   if(this.uri === null){

    //     return;
    //   } else {

    //   }
    // }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;
#modal-sal {
  .yo-good-table::v-deep .vgt-global-search {
    border-top: 5px solid #2f2f2f1b;
    border-left: 5px solid #2f2f2f1b;
    border-right: 5px solid #2f2f2f1b;
    .vgt-global-search__input {
      border: none !important;
      .input__icon {
        font-weight: 700 !important;
      }
      .vgt-input {
        font-weight: 700 !important;
        color: black;
        border: double;
      }
    }
  }
  .yo-good-table::v-deep .vgt-table {
    border: $color-yo-border !important;
    width: 100% !important;
    thead {
      th {
        border: $color-yo-border !important;
      } /* header custom */
      .filter-th {
        input {
          border: $color-yo-border !important;
          font-weight: bolder;
        }
      }
    }
    tbody {
      tr.custom-transition {
        transition: opacity 0.5s ease;
      }
      tr.custom-transition-active,
      tr.custom-transition-leave-active {
        transition: opacity 0.5s ease;
      }
      tr.custom-transition-enter-from,
      tr.custom-transition-leave-to {
        opacity: 0;
      }

      th {
        border: $color-yo-border !important;
      } /* left checkbox */
      td {
        border: $color-yo-border !important;
      } /* right checkbox */
    }
  }
}
</style>